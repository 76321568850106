import React from "react"
import styles from './header.module.css'

const Header = () => {
  return (
      <div className="container">
        <div className={styles.headerContent}>
          <h1 className={styles.heroText}>The Jolly mommy</h1>
        </div>
      </div>
  )
}

export default Header
