import React from "react"
import styles from "./postSingle.module.css"
import Image from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const PostSingle = ({ post }) => {
  const { slug, title, mainImage, createdAt, category, content: { json } } = post

  const subString = documentToReactComponents(json)[0].props.children[0].toString().substr(0,250)

  return (
    <section className="container">
      <div className={`card mb-3 ${styles.cardMainContainer}`}>
        <Image fluid={mainImage.fluid} className={`card-img-top ${styles.imgContainer}`}
               alt='post'/>
        <div className={`card-body ${styles.cardBodyContainer}`}>
          <span><a className={styles.spanText} href="/">Jolly Mommy</a> / <a className={styles.spanText}
                                                                             href="/">{createdAt}</a> / <a
            className={styles.spanText} href="/">{category.title}</a></span>
          <h2 className="card-title text-dark">{title}</h2>
          <p className="card-text text-dark">{subString} ...</p>

          <AniLink fade to={`/post/${slug}`} className={styles.cardFooterText}>Continue Reading</AniLink>
        </div>
      </div>
    </section>
  )
}

export default PostSingle
