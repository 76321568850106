export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/post",
    text: "posts",
  },
]
