import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../global.css'
import Nav from "../Nav/Nav"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"

const Layout = ({children}) => {
  return (
    <main>
      <Nav />
      <Header/>
      {children}
      <Footer/>
    </main>
  )
}

export default Layout
