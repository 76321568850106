import React, { useState } from "react"
import styles from "./nav.module.css"
import social from "../../constants/social-links"
import links from "../../constants/links"
import { FaBabyCarriage } from "react-icons/fa"

const Nav = () => {
  const [isOpen, setNav] = (useState(false))
  const toggleNav = () => {
    setNav(!isOpen)
  }
  const show = false

  return (
    <nav className={styles.nav}>
      <div className={`${styles.navMenu} flex-row`}>
        <div className={styles.navBrand}>
          <a href='/' className={styles.textGray}>TJM</a>
        </div>
        <div className={styles.toggleCollapse}>
          <div className={styles.toggleIcons}>
            <FaBabyCarriage className={styles.toggleIcon} onClick={toggleNav}/>
          </div>
        </div>
        <div>
          <ul className={isOpen ? `${styles.navItems}` : `${styles.navItems}`}>
            {
              links.map((link, index) => {
                return (
                  <li key={index} className={styles.navLink}>
                    <a href={link.path}>{link.text}</a>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className={styles.social}>
          {
            social.map((link, index) => {
              return (
                <a hidden={true} key={index} href={link.url} target='_blank' rel='noopener noreferrer'>
                  {link.icon}
                </a>
              )
            })
          }
        </div>


      </div>
    </nav>
  )
}

export default Nav
