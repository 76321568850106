import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="footer-copyright text-center py-3">© 2020 Copyright:
        <> The Jolly Mommy</>
      </div>
      <div className='text-center text-muted small mb-3'>
        Website:
        <a className='text-muted' href="https://www.nolanjames.me" target="_blank" rel="noopener noreferrer"> Nolan James</a>
      </div>
    </footer>
  )
}

export default Footer
